(function($){
    $('.actu .twitter-link').on('click', function(e){
        var product_url = encodeURIComponent(location.href),
            product_name = encodeURIComponent($('h1').text());
        window.open('https://twitter.com/intent/tweet?text=' + product_name + ' ' + product_url, 'sharertwt', 'toolbar=0,status=0,width=640,height=445');
        e.preventDefault();
    });

    $('.actu .facebook-link').on('click', function(e){
        window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(location.href), 'sharer', 'toolbar=0,status=0,width=660,height=445');
        e.preventDefault();
    });

    $('.actu .google-link').on('click', function(e){
        window.open('https://plus.google.com/share?url=' + encodeURIComponent(location.href), 'sharergplus', 'toolbar=0,status=0,width=660,height=445');
        e.preventDefault();
    });
})(jQuery);
