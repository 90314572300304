
var fnSameHeights = function(elems) {
  elems.css('height', 'auto');
  elems.css('height', Math.max.apply(Math, elems.map(function() {
    return $(this).outerHeight();
  }).get() ));
}

var $equalHeights = $('.sameheights')
var $stepContent = $('.cols-step >.container >.row >.vc_column_container')
var $thumbsContent = $('.bloc-sticky-thumbs >.container >.row >.vc_column_container');
fnSameHeights($equalHeights);
fnSameHeights($stepContent);
fnSameHeights($thumbsContent);

window.resizedFinished = setTimeout(function(){
  fnSameHeights($equalHeights);
  fnSameHeights($stepContent);
  fnSameHeights($thumbsContent);
}, 200);


$(window).resize(function() {
    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(function(){
      fnSameHeights($equalHeights);
      fnSameHeights($stepContent);
      fnSameHeights($thumbsContent);
    }, 250);
});
